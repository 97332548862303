import axios, { isAxiosError } from 'axios';

import ApiResponseError from './ApiResponseError';

interface Headers {
  [key: string]: string | undefined;
}

export interface ApiClientOptions {
  url: string;
  method?: 'get' | 'GET' | 'post' | 'POST';
  headers?: Headers;
  body?: string;
}

const apiClient = async <T = void>(options: ApiClientOptions): Promise<T | undefined> => {
  try {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      ...options.headers,
    };

    const response = await axios({
      method: options.method || 'GET',
      url: options.url,
      headers,
      data: options.body,
    });

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new ApiResponseError(error.response.status, error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw new ApiResponseError(500, error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new ApiResponseError(500, error.message);
      }
    }
  }
};

export default apiClient;

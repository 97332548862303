import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

import createCachePromise from '~/helpers/createCachePromise';

import { CustomerAddress } from '../useAddress';
import useCustomerScopedAccessToken from '../useCustomerScopedAccessToken';
import createStorefrontCustomerAccessToken from './createStorefrontCustomerAccessToken';
import customerEmailMarketingSubscribe from './customerEmailMarketingSubscribe';
import customerEmailMarketingUnsubscribe from './customerEmailMarketingUnsubscribe';
import getCustomerDetails, { CustomerDetailsResponse } from './getCustomerDetails';
import mapToCustomer from './mapToCustomer';
import { EmailMarketingState } from './useCustomer.types';
import useFetchBuyerOnLogin from './useFetchBuyerOnLogin';

export interface Customer {
  id?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  emailAddress?: string;
  emailMarketingState?: EmailMarketingState;
  phoneNumber?: string;
  companyLocationId?: string;
  addresses: CustomerAddress[];
  defaultAddress?: CustomerAddress;
}

export interface UseCustomerError {
  customer: string | null;
  emailMarketingSubscription: string | null;
  buyer: string | null;
}

export interface BuyerIdentity {
  customerAccessToken: string | null;
  companyLocationId: string | null;
}

const customer = ref<Customer | null>(null);

const buyer = ref<BuyerIdentity | null>(null);

export const setCustomer = (newCustomer: Customer | null) => {
  customer.value = newCustomer;
};

export const setBuyer = (newBuyer: BuyerIdentity | null) => {
  buyer.value = newBuyer;
};

// eslint-disable-next-line max-lines-per-function
const useCustomer = () => {
  const { $config } = useContext();

  const { fetchCustomerScopedAccessToken } = useCustomerScopedAccessToken();

  const error = ref<UseCustomerError>({
    buyer: null,
    customer: null,
    emailMarketingSubscription: null,
  });
  const isLoading = ref(false);

  const setError = (key: keyof UseCustomerError, message: string) => {
    error.value = { ...error.value, [key]: message };
  };

  const handleCreateStorefrontCustomerAccessToken = async (customerScopedAccessToken: string) => {
    const storefrontCustomerAccessTokenResponse = await createCachePromise(
      async () =>
        await createStorefrontCustomerAccessToken({
          customerScopedAccessToken,
          customerAccountEndpoint: $config.customerAccountEndpoint,
        }),
    );

    const token =
      storefrontCustomerAccessTokenResponse?.data?.storefrontCustomerAccessTokenCreate
        ?.customerAccessToken;

    if (!token) {
      throw new Error('Failed to create storefront customer access token.');
    }

    return token;
  };

  const fetchCustomer = async () => {
    try {
      const customerScopedToken = await fetchCustomerScopedAccessToken();

      const customerDetails = await createCachePromise<CustomerDetailsResponse>(
        async () =>
          await getCustomerDetails({
            accessToken: customerScopedToken.access_token,
            customerAccountEndpoint: $config.customerAccountEndpoint,
          }),
      );

      customer.value = mapToCustomer(customerDetails);

      return customer.value;
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Failed to fetch customer.';
      Logger.error(errorMessage);
      setError('customer', errorMessage);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchBuyer = async () => {
    try {
      isLoading.value = true;

      if (buyer.value) return buyer.value;

      const { access_token: customerScopedAccessToken } = await fetchCustomerScopedAccessToken();

      const customerAccessToken = await handleCreateStorefrontCustomerAccessToken(
        customerScopedAccessToken,
      );

      buyer.value = {
        customerAccessToken,
        companyLocationId: customer.value?.companyLocationId || null,
      };

      return buyer.value;
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Failed to fetch buyer.';
      Logger.error(errorMessage);
      setError('buyer', errorMessage);
    } finally {
      isLoading.value = false;
    }
  };

  const handleCustomerEmailMarketingSubscribe = async () => {
    const { access_token: customerScopedAccessToken } = await fetchCustomerScopedAccessToken();

    try {
      isLoading.value = true;
      return await customerEmailMarketingSubscribe({
        customerScopedAccessToken,
        customerAccountEndpoint: $config.customerAccountEndpoint,
      });
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Failed to subscribe email marketing.';
      Logger.error(errorMessage);
      setError('emailMarketingSubscription', errorMessage);
    } finally {
      isLoading.value = false;
    }
  };

  const handleCustomerEmailMarketingUnsubscribe = async () => {
    const { access_token: customerScopedAccessToken } = await fetchCustomerScopedAccessToken();

    try {
      isLoading.value = true;
      return await customerEmailMarketingUnsubscribe({
        customerScopedAccessToken,
        customerAccountEndpoint: $config.customerAccountEndpoint,
      });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'Failed to unsubscribe email marketing.';
      Logger.error(errorMessage);
      setError('emailMarketingSubscription', errorMessage);
    } finally {
      isLoading.value = false;
    }
  };

  useFetchBuyerOnLogin({
    fetchBuyer: async () => {
      if (!customer.value) {
        await fetchCustomer();
      }

      if (!error.value.customer) {
        return await fetchBuyer();
      }
    },
  });

  return {
    customer,
    fetchCustomer,
    emailMarketingSubscribe: handleCustomerEmailMarketingSubscribe,
    emailMarketingUnsubscribe: handleCustomerEmailMarketingUnsubscribe,
    error,
    buyer,
    isLoading,
  };
};

export default useCustomer;

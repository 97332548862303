<template>
  <div id="vehicles">
    <BaseSidebar
      :visible="isVehicleSelectionSidebarOpen || isGarageSelectionSidebarOpen"
      class="sf-sidebar--right"
      @close="toggleSidebar"
    >
      <transition name="sf-fade" mode="out-in">
        <div class="vehicles-sidebar">
          <div class="vehicles-sidebar-header">
            <FontAwesomeIcon icon="fa-solid fa-warehouse" class="vehicles-sidebar-header-icon" />
            <div class="vehicles-sidebar-header-content">
              <h4 class="vehicles-sidebar-header-content-title">My Bikes</h4>
              <div class="vehicles-sidebar-header-content-desc">
                Find the parts designed to fit your bike
              </div>
            </div>
          </div>
          <VehicleSelection v-if="isVehicleSelectionSidebarOpen" v-on="$listeners" />
          <GarageSelection v-if="isGarageSelectionSidebarOpen" v-on="$listeners" />
        </div>
      </transition>
    </BaseSidebar>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BaseSidebar } from '@unified-commerce/gpc-ui-library';

import GarageSelection from '~/components/Garage/GarageSelection/GarageSelection.vue';
import VehicleSelection from '~/components/VehicleSelection/VehicleSelection.vue';
import { useUiState } from '~/composables';

export default {
  name: 'VehiclesSideBar',
  components: {
    BaseSidebar,
    FontAwesomeIcon,
    VehicleSelection,
    GarageSelection,
  },
  setup() {
    const {
      isVehicleSelectionSidebarOpen,
      toggleVehicleSelectionSidebar,
      isGarageSelectionSidebarOpen,
      toggleGarageSelectionSidebar,
    } = useUiState();

    return {
      isVehicleSelectionSidebarOpen,
      toggleVehicleSelectionSidebar,
      isGarageSelectionSidebarOpen,
      toggleGarageSelectionSidebar,
    };
  },
  methods: {
    toggleSidebar() {
      this.isVehicleSelectionSidebarOpen
        ? this.toggleVehicleSelectionSidebar()
        : this.toggleGarageSelectionSidebar();
    },
  },
};
</script>

<style lang="scss" scoped>
#vehicles {
  --sidebar-z-index: var(--modal-z-index);
  --overlay-z-index: var(--modal-z-index);
  --sidebar-content-padding: 0;
  --sidebar-circle-icon-display: flex;
  @include md-devices-min {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-width: 38.5rem;
      --sidebar-circle-icon-display: none;
      --sidebar-content-padding: 0;
      --sidebar-left: auto;
    }
  }

  ::v-deep .desktop-only {
    display: unset !important;
  }
  ::v-deep .sf-bar {
    display: none;
  }

  ::v-deep .sf-sidebar__circle-icon {
    top: unset;
    transform: unset;
    --button-border-radius: 0;
    --button-box-shadow: unset;
    --button-background: var(--c-black);
    --icon-color: white;
    --button-padding: var(--spacer-sm);
    --button-size: unset;
  }
}

.vehicles-sidebar {
  ::v-deep .sf-collected-product__quantity-wrapper {
    position: unset;
  }

  ::v-deep .sf-collected-product__actions {
    margin: var(--spacer-3sm) 0 var(--spacer-2lg) 0;
  }

  &-header {
    &-icon {
      padding-left: var(--spacer-sm);
      color: #ffffff;
    }

    background-color: var(--c-black);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: var(--spacer-4base);
    padding: var(--spacer-sm) 0;
    @include md-devices-min {
      padding: var(--spacer-base) 0;
    }
    &-content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-content: center;
      padding-right: var(--spacer-sm);

      @include md-devices-min {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        column-gap: var(--spacer-sm);
      }

      &-title {
        font-family: var(--font-family--heading);
        font-weight: var(--font-weight--bold);
        font-size: var(--font-size--3xl);
        letter-spacing: -0.04em;
        text-transform: none;
        color: white;
      }

      &-desc {
        font-weight: var(--font-weight--normal);
        font-size: var(--font-size--base);
        color: white;
      }
    }
  }

  svg {
    width: var(--jeep-icon-width, 60px);
    height: var(--jeep-icon-height, 60px);

    @include md-devices-min {
      width: var(--jeep-icon-width, 40px);
      height: var(--jeep-icon-height, 40px);
    }
  }
}
</style>

import addSeconds from 'date-fns/addSeconds';

export const generateRandomCode = (): string => {
  // eslint-disable-next-line no-magic-numbers
  const array = new Uint8Array(32);
  crypto.getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array));
};

// generate a code verifier
export const generateCodeVerifier = (): string => {
  const rando = generateRandomCode();
  return base64UrlEncode(rando);
};

export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const digestOp = await crypto.subtle.digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(codeVerifier),
  );
  const hash = convertBufferToString(digestOp);
  return base64UrlEncode(hash);
};

// base64 URL encode a string
export const base64UrlEncode = (str: string): string => {
  const base64 = btoa(str);
  // This is to ensure that the encoding does not have +, /, or = characters in it.
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const convertBufferToString = (hash: ArrayBuffer): string => {
  const uintArray = new Uint8Array(hash);
  const numberArray = Array.from(uintArray);
  return String.fromCharCode(...numberArray);
};

// generate a state string
export const generateState = (): string => {
  const timestamp = Date.now().toString();
  // eslint-disable-next-line no-magic-numbers
  const randomString = Math.random().toString(36).substring(2);
  return timestamp + randomString;
};

// generate a nonce of a specified length
export const generateNonce = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let nonce = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    nonce += characters.charAt(randomIndex);
  }

  return nonce;
};

export const getExpireTimeInUtc = (expiresIn: number, date = new Date()) => {
  const expiresTime = addSeconds(date, expiresIn);

  return expiresTime.toISOString();
};

import apiClient from '~/api/apiClient';

import { CustomerAddress, customerAddressQuery } from '../useAddress';
import { EmailMarketingState } from './useCustomer.types';

export interface GetCustomerDetailsProps {
  accessToken: string;
  customerAccountEndpoint: string;
}

export interface CustomerDetailsResponse {
  data?: {
    customer?: {
      id?: string;
      firstName?: string;
      lastName?: string;
      displayName?: string;
      emailAddress?: {
        emailAddress?: string;
        marketingState?: EmailMarketingState;
      };
      phoneNumber?: {
        phoneNumber?: string;
      };
      addresses?: {
        edges?: {
          node?: CustomerAddress;
        }[];
      };
      defaultAddress?: {
        id?: string;
      };
      companyContacts?: {
        edges?: {
          node?: {
            company?: {
              locations?: {
                edges?: {
                  node?: {
                    id?: string;
                  };
                }[];
              };
            };
          };
        }[];
      };
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = () => {
  return JSON.stringify({
    query: `
      query { 
        customer {
          id
          firstName
          lastName
          displayName
          emailAddress {
            emailAddress
            marketingState
          }
          phoneNumber {
            phoneNumber
          }
          addresses(first: 250) {
            edges {
                node {
                  ${customerAddressQuery}
                }
            }
          }
          defaultAddress {
            id
          }
          companyContacts(first: 5) {
            edges {
                node {
                    company {
                        locations(first: 5) {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                    }
                }
            }
          }
        }
      }
    `,
  });
};

const verifyResponse = (response: CustomerDetailsResponse | undefined) => {
  if (!response) {
    throw new Error('Failed to fetch customer details.');
  }

  if (Array.isArray(response.errors)) {
    throw new Error(response.errors[0].message);
  }

  return response;
};

const getCustomerDetails = async ({
  accessToken,
  customerAccountEndpoint,
}: GetCustomerDetailsProps): Promise<CustomerDetailsResponse> => {
  const headers = {
    Authorization: accessToken,
  };

  const response = await apiClient<CustomerDetailsResponse>({
    url: customerAccountEndpoint,
    body: generateQuery(),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default getCustomerDetails;

import apiClient from '~/api/apiClient';

import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartResponse } from './useBuyerCart.types';

export interface GetCartProps {
  cartEndpoint: string;
  cartId: string;
  shopifyStorefrontToken: string;
}

export interface GetCartResponse {
  data?: {
    cart?: CartResponse;
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = ({ cartId }: Pick<GetCartProps, 'cartId'>) => {
  return JSON.stringify({
    query: `
        query($id: ID!) {
            cart(id: $id) {
              ${cartQuery}
            }
        }
    `,
    variables: { id: cartId },
  });
};

const verifyResponse = (response: GetCartResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to get cart.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cart);
};

const getCart = async ({
  cartId,
  cartEndpoint,
  shopifyStorefrontToken,
}: GetCartProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<GetCartResponse>({
    url: cartEndpoint,
    body: generateQuery({ cartId }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default getCart;

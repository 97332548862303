import apiClient from '~/api/apiClient';

import { BuyerIdentity } from '../useCustomer';
import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartResponse } from './useBuyerCart.types';

export interface CartCreateProps {
  cartEndpoint: string;
  buyerIdentity: BuyerIdentity | null;
  shopifyStorefrontToken: string;
}

export interface CartCreateResponse {
  data?: {
    cartCreate?: {
      cart?: CartResponse;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateMutation = ({ buyerIdentity }: Pick<CartCreateProps, 'buyerIdentity'>) => {
  return JSON.stringify({
    query: `
    mutation cartCreate($input:CartInput!) {
        cartCreate(input:$input) {
            cart {
              ${cartQuery}
            }
        }   
    }
    `,
    variables: { input: { buyerIdentity } },
  });
};

const verifyResponse = (response: CartCreateResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to create cart.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cartCreate?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cartCreate.cart);
};

const cartCreate = async ({
  buyerIdentity,
  cartEndpoint,
  shopifyStorefrontToken,
}: CartCreateProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<CartCreateResponse>({
    url: cartEndpoint,
    body: generateMutation({ buyerIdentity }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default cartCreate;

import { ref, useContext } from '@nuxtjs/composition-api';

import useCustomerScopedAccessToken from '../useCustomerScopedAccessToken';
import customerAddressCreate from './customerAddressCreate';
import customerAddressDelete from './customerAddressDelete';
import customerAddressUpdate from './customerAddressUpdate';
import { CustomerAddressInput } from './useAddress.types';

export interface UseAddressErrors {
  create: string | null;
  update: string | null;
  delete: string | null;
}

// eslint-disable-next-line max-lines-per-function
const useAddress = () => {
  const isLoading = ref(false);
  const errors = ref<UseAddressErrors>({ create: null, update: null, delete: null });

  const { $config } = useContext();
  const { fetchCustomerScopedAccessToken } = useCustomerScopedAccessToken();

  const handleCustomerAddressCreate = async (address: CustomerAddressInput) => {
    const { access_token: accessToken } = await fetchCustomerScopedAccessToken();

    try {
      errors.value.create = null;
      isLoading.value = true;

      return await customerAddressCreate({
        customerScopedAccessToken: accessToken,
        customerAccountEndpoint: $config.customerAccountEndpoint,
        address,
      });
    } catch (err) {
      errors.value = {
        ...errors.value,
        create: err instanceof Error ? err.message : String(err),
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleCustomerAddressDelete = async (addressId: string) => {
    const { access_token: accessToken } = await fetchCustomerScopedAccessToken();

    try {
      errors.value.delete = null;
      isLoading.value = true;

      return await customerAddressDelete({
        customerScopedAccessToken: accessToken,
        customerAccountEndpoint: $config.customerAccountEndpoint,
        addressId,
      });
    } catch (err) {
      errors.value = {
        ...errors.value,
        delete: err instanceof Error ? err.message : String(err),
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleCustomerAddressUpdate = async ({
    address,
    addressId,
    defaultAddress,
  }: {
    address?: CustomerAddressInput;
    addressId: string;
    defaultAddress?: boolean;
  }) => {
    const { access_token: accessToken } = await fetchCustomerScopedAccessToken();

    try {
      errors.value.update = null;
      isLoading.value = true;

      return await customerAddressUpdate({
        customerScopedAccessToken: accessToken,
        customerAccountEndpoint: $config.customerAccountEndpoint,
        address,
        addressId,
        defaultAddress,
      });
    } catch (err) {
      errors.value = {
        ...errors.value,
        update: err instanceof Error ? err.message : String(err),
      };
    } finally {
      isLoading.value = false;
    }
  };

  return {
    customerAddressCreate: handleCustomerAddressCreate,
    customerAddressDelete: handleCustomerAddressDelete,
    customerAddressUpdate: handleCustomerAddressUpdate,
    errors,
    isLoading,
  };
};

export default useAddress;

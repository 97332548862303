export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    const isRootPath = to.path === '/';
    const hasTrailingSlash = to.path.endsWith('/') && !isRootPath;

    if (hasTrailingSlash) {
      const newPath = to.path.replace(/\/+$/, '');
      const queryAndHash = to.fullPath.slice(to.path.length);

      const sanitizedPath = `${newPath}${queryAndHash}`;

      if (sanitizedPath !== to.fullPath) {
        return next(encodeURI(sanitizedPath));
      }
    }

    next();
  });
};

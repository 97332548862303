import { load, remove, save } from '~/helpers/localStorage';

import { CART_KEY } from './useBuyerCart.constants';
import { CartStorage } from './useBuyerCart.types';

export const getCartFromStorage = (): CartStorage | null => {
  return load<CartStorage>(CART_KEY);
};

export const saveCartToStorage = (cart: CartStorage) => {
  save<CartStorage>(CART_KEY, cart);
};

export const removeCartFromStorage = () => {
  remove(CART_KEY);
};

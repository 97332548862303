import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f232b84 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6f4af097 = () => interopDefault(import('../pages/account/account-details.vue' /* webpackChunkName: "pages/account/account-details" */))
const _00cfd357 = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _dc723f8a = () => interopDefault(import('../pages/account/manage-marketing-emails.vue' /* webpackChunkName: "pages/account/manage-marketing-emails" */))
const _3191e008 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _b515fa04 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _1784140a = () => interopDefault(import('../pages/content-hub/index.vue' /* webpackChunkName: "pages/content-hub/index" */))
const _c08062fa = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _ac6e5300 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4739fbfa = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _8b7c008c = () => interopDefault(import('../pages/parts/index.vue' /* webpackChunkName: "pages/parts/index" */))
const _05816656 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "" */))
const _6077d9be = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _aa0f5410 = () => interopDefault(import('../pages/zip.vue' /* webpackChunkName: "pages/zip" */))
const _1af1569a = () => interopDefault(import('../pages/content-hub/_slug.vue' /* webpackChunkName: "" */))
const _0aa84470 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "" */))
const _8ee9431c = () => interopDefault(import('../pages/parts/_slug.vue' /* webpackChunkName: "pages/parts/_slug" */))
const _4ba84818 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _a7066dde = () => interopDefault(import('../pages/stores/_id/_slug.vue' /* webpackChunkName: "pages/stores/_id/_slug" */))
const _3fbd992e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _7f232b84,
    name: "account___en",
    children: [{
      path: "account-details",
      component: _6f4af097,
      name: "account-account-details___en"
    }, {
      path: "addresses",
      component: _00cfd357,
      name: "account-addresses___en"
    }, {
      path: "manage-marketing-emails",
      component: _dc723f8a,
      name: "account-manage-marketing-emails___en"
    }]
  }, {
    path: "/brands",
    component: _3191e008,
    name: "brands___en"
  }, {
    path: "/callback",
    component: _b515fa04,
    name: "callback___en"
  }, {
    path: "/content-hub",
    component: _1784140a,
    name: "content-hub___en"
  }, {
    path: "/healthcheck",
    component: _c08062fa,
    name: "healthcheck___en"
  }, {
    path: "/login",
    component: _ac6e5300,
    name: "login___en"
  }, {
    path: "/logout",
    component: _4739fbfa,
    name: "logout___en"
  }, {
    path: "/parts",
    component: _8b7c008c,
    name: "parts___en"
  }, {
    path: "/promotions",
    component: _05816656,
    name: "promotions___en"
  }, {
    path: "/search",
    component: _6077d9be,
    name: "search___en"
  }, {
    path: "/zip",
    component: _aa0f5410,
    name: "zip___en"
  }, {
    path: "/content-hub/articles/:slug*",
    component: _1af1569a,
    name: "articles___en"
  }, {
    path: "/content-hub/videos/:slug*",
    component: _1af1569a,
    name: "videos___en"
  }, {
    path: "/content/brands/:slug",
    component: _0aa84470,
    name: "brands-pages___en"
  }, {
    path: "/brands/:id",
    component: _0aa84470,
    name: "brands-id___en"
  }, {
    path: "/content-hub/:slug?",
    component: _1af1569a,
    name: "content-hub-slug___en"
  }, {
    path: "/pages/:slug?",
    component: _05816656,
    name: "pages-slug___en"
  }, {
    path: "/parts/:slug",
    component: _8ee9431c,
    name: "parts-slug___en"
  }, {
    path: "/products/:slug?",
    component: _4ba84818,
    name: "products-slug___en"
  }, {
    path: "/content-hub/:filterLabel/:filter+",
    component: _1784140a,
    name: "content-hub-filtered___en"
  }, {
    path: "/stores/:id?/:slug?",
    component: _a7066dde,
    name: "stores-id-slug___en"
  }, {
    path: "/legends/:slug?/:slug2?/:slug3?",
    component: _05816656,
    name: "legends___en"
  }, {
    path: "/parts/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _8ee9431c,
    name: "categories-pages___en"
  }, {
    path: "/",
    component: _3fbd992e,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

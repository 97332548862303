var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseHero',{staticClass:"banner-carousel",attrs:{"slider-options":{ type: 'carousel', disableButtonsAtBounds: true, autoplay: false }},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var go = ref.go;
var disabled = ref.disabled;
return [_c('BaseButton',{staticClass:"sf-button--pure sf-button button button--left",style:({
        opacity: disabled ? 0.2 : 1,
        cursor: disabled ? 'default' : 'pointer',
      }),attrs:{"aria-label":'previous',"aria-disabled":disabled},on:{"click":function () { return !disabled && go('prev'); }}},[_c('BaseIcon',{attrs:{"icon":"chevron_left","color":"white"}})],1)]}},{key:"next",fn:function(ref){
      var go = ref.go;
      var disabled = ref.disabled;
return [_c('BaseButton',{staticClass:"sf-button--pure sf-button button button--right",style:({
        opacity: disabled ? 0.2 : 1,
        cursor: disabled ? 'default' : 'pointer',
      }),attrs:{"aria-label":'next',"aria-disabled":disabled},on:{"click":function () { return !disabled && go('next'); }}},[_c('BaseIcon',{attrs:{"icon":"chevron_right","color":"white"}})],1)]}}])},_vm._l((_vm.banners),function(banner){return _c('BaseCarouselItem',{key:banner.uid,staticClass:"item"},[_c('BaseLink',{attrs:{"link":_vm.getLink(banner.link.cached_url)}},[_c('BannerImage',_vm._b({},'BannerImage',banner,false))],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
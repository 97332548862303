import apiClient from '~/api/apiClient';

export interface CreateStorefrontCustomerAccessTokenProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
}

export interface CreateStorefrontCustomerAccessTokenResponse {
  data?: {
    storefrontCustomerAccessTokenCreate?: {
      customerAccessToken?: string;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = () => {
  return JSON.stringify({
    query: `
      mutation storefrontCustomerAccessTokenCreate {
        storefrontCustomerAccessTokenCreate {
          customerAccessToken
        }
      }
    `,
  });
};

const createStorefrontCustomerAccessToken = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
}: CreateStorefrontCustomerAccessTokenProps): Promise<
  CreateStorefrontCustomerAccessTokenResponse | undefined
> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CreateStorefrontCustomerAccessTokenResponse>({
    url: customerAccountEndpoint,
    body: generateQuery(),
    headers,
    method: 'POST',
  });

  return response;
};

export default createStorefrontCustomerAccessToken;

import apiClient from '~/api/apiClient';

export interface CustomerAddressDeleteProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
  addressId: string;
}

export interface CustomerAddressDeleteResponse {
  data?: {
    customerAddressDelete?: {
      deletedAddressId?: string;
      userErrors?: {
        message?: string;
      }[];
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = ({ addressId }: Pick<CustomerAddressDeleteProps, 'addressId'>) => {
  return JSON.stringify({
    query: `
      mutation customerAddressDelete($addressId: ID!) {
        customerAddressDelete(addressId: $addressId) {
          deletedAddressId
          userErrors {
            message
          }
        }
      }
    `,
    variables: { addressId },
  });
};

const customerAddressDelete = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
  addressId,
}: CustomerAddressDeleteProps): Promise<CustomerAddressDeleteResponse | undefined> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CustomerAddressDeleteResponse>({
    url: customerAccountEndpoint,
    body: generateQuery({ addressId }),
    headers,
    method: 'POST',
  });

  if (response?.errors && response.errors.length > 0) {
    throw new Error(response.errors.map((error) => error.message).join('; '));
  }

  if (
    response?.data?.customerAddressDelete?.userErrors &&
    response.data.customerAddressDelete.userErrors.length > 0
  ) {
    throw new Error(
      response.data.customerAddressDelete.userErrors.map((error) => error.message).join('; '),
    );
  }

  return response;
};

export default customerAddressDelete;

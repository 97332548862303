import { AgnosticAttribute } from '@vue-storefront/core';

import { CartLine } from './useBuyerCart.types';

export const getCartItemImage = (imageSrc: string): string => {
  const imgPath = imageSrc.substring(0, imageSrc.lastIndexOf('.'));
  const imgext = imageSrc.split('.')?.pop();
  const cartImg = `${imgPath}_120x120.${imgext}`;
  return cartImg;
};

export const getPrice = (cartLine: CartLine) => {
  return cartLine.compareAtPriceV2 || cartLine.priceV2;
};

export const getCompareToPrice = (cartLine: CartLine) => {
  if (cartLine.compareAtPriceV2) {
    return cartLine.priceV2;
  }

  return null;
};

const isFreeSale = (cartLine: CartLine) =>
  cartLine.availableForSale && cartLine.totalInventory <= 0;

export const getCartItemMaximumQuantity = (
  cartLine: CartLine,
  options: { supportFreeSale: boolean } = { supportFreeSale: false },
): number => {
  return options.supportFreeSale && isFreeSale(cartLine) ? 100 : cartLine.totalInventory;
};

const formatSelectedAttributeList = (
  attributes: { name: string; value: string }[],
): AgnosticAttribute[] =>
  attributes.map((attr) => {
    return {
      name: attr.name,
      value: attr.value,
      label: attr.name,
    };
  });

export const getCartItemAttributes = (cartLine: CartLine) => {
  const formatAttedattributeList = formatSelectedAttributeList(cartLine.selectedOptions);
  if (formatAttedattributeList?.length) {
    const attribArr: any = {};
    formatAttedattributeList?.forEach((attr) => {
      attribArr[attr?.name as any] = attr?.value;
    });
    return { ...attribArr };
  }
  return {};
};

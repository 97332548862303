import apiClient from '~/api/apiClient';

import { EmailMarketingState } from './useCustomer.types';

export interface CustomerEmailMarketingSubscribeProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
}

export interface CustomerEmailMarketingSubscribeDataResponse {
  emailAddress?: {
    emailAddress?: string;
    marketingState?: EmailMarketingState;
  };
}

export interface CustomerEmailMarketingSubscribeResponse {
  data?: {
    customerEmailMarketingSubscribe?: {
      userErrors?: {
        message?: string;
      }[];
    } & CustomerEmailMarketingSubscribeDataResponse;
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = () => {
  return JSON.stringify({
    query: `
      mutation customerEmailMarketingSubscribe {
        customerEmailMarketingSubscribe {
          emailAddress {
            emailAddress
            marketingState
          }
          userErrors {
            message
          }
        }
      }
    `,
  });
};

const customerEmailMarketingSubscribe = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
}: CustomerEmailMarketingSubscribeProps): Promise<
  CustomerEmailMarketingSubscribeDataResponse | undefined
> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CustomerEmailMarketingSubscribeResponse>({
    url: customerAccountEndpoint,
    body: generateQuery(),
    headers,
    method: 'POST',
  });

  if (response?.errors && response.errors.length > 0) {
    throw new Error(response.errors.map((error) => error.message).join('; '));
  }

  if (
    response?.data?.customerEmailMarketingSubscribe?.userErrors &&
    response.data.customerEmailMarketingSubscribe.userErrors.length > 0
  ) {
    throw new Error(
      response.data.customerEmailMarketingSubscribe.userErrors
        .map((error) => error.message)
        .join('; '),
    );
  }

  return response?.data?.customerEmailMarketingSubscribe;
};

export default customerEmailMarketingSubscribe;

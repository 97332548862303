import { ref, useContext } from '@nuxtjs/composition-api';

import createCachePromise from '~/helpers/createCachePromise';

import useAuth from '../useAuth';
import getCustomerScopedAccessToken, {
  CustomerScopedAccessTokenResponse,
} from './getCustomerScopedAccessToken';

const customerScopedAccessToken = ref<CustomerScopedAccessTokenResponse | null>(null);

export const setCustomerScopedToken = (
  newCustomerScopedToken: CustomerScopedAccessTokenResponse | null,
) => {
  customerScopedAccessToken.value = newCustomerScopedToken;
};

const useCustomerScopedAccessToken = () => {
  const { $config } = useContext();
  const { accessToken } = useAuth();

  const fetchCustomerScopedAccessToken = async () => {
    if (customerScopedAccessToken.value) return customerScopedAccessToken.value;

    return await createCachePromise<CustomerScopedAccessTokenResponse>(async () => {
      if (!accessToken.value) {
        throw new Error(
          'Failed to retrieve access token while fetching customer scoped access token.',
        );
      }

      customerScopedAccessToken.value = await getCustomerScopedAccessToken({
        clientId: $config.clientId,
        accessToken: accessToken.value,
        accessTokenEndpoint: $config.accessTokenEndpoint,
        tokenExchangeAudience: $config.tokenExchangeAudience,
      });

      return customerScopedAccessToken.value;
    });
  };

  return {
    fetchCustomerScopedAccessToken,
  };
};

export default useCustomerScopedAccessToken;

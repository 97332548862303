import Vue from 'vue';

import BannerCarousel from '~/components/BannerCarousel/BannerCarousel.vue';
import BannerImage from '~/components/BannerImage/BannerImage.vue';
import AnchorLinkedBlocks from '~/components/CMS/AnchorLinkedBlocks/AnchorLinkedBlocks.vue';
import Author from '~/components/CMS/Author/Author.vue';
import BrandCarousel from '~/components/CMS/BrandCarousel/BrandCarousel.vue';
import BrandHero from '~/components/CMS/BrandHero/BrandHero.vue';
import CategoryCarousel from '~/components/CMS/CategoryCarousel/CategoryCarousel.vue';
import ContentCards from '~/components/CMS/ContentCards/ContentCards.vue';
import FeaturedPromotion from '~/components/CMS/FeaturedPromotion/FeaturedPromotion.vue';
import HeroCarousel from '~/components/CMS/HeroCarousel/HeroCarousel.vue';
import HeroImageTitle from '~/components/CMS/HeroImageTitle/HeroImageTitle.vue';
import HeroTitle from '~/components/CMS/HeroTitle.vue';
import KlaviyoFormEmbed from '~/components/CMS/KlaviyoFormEmbed/KlaviyoFormEmbed.vue';
import LegendaryGrid from '~/components/CMS/Legendary/LegendaryGrid.vue';
import LegendaryHeroBlock from '~/components/CMS/Legendary/LegendaryHeroBlock.vue';
import PageTitle from '~/components/CMS/PageTitle/PageTitle.vue';
import PopularCategoriesCarousel from '~/components/CMS/PopularCategoriesCarousel/PopularCategoriesCarousel.vue';
import ProductCarouselAuto from '~/components/CMS/ProductCarouselAuto/ProductCarouselAuto';
import PromotionContent from '~/components/CMS/PromotionContent/PromotionContent.vue';
import PromotionsGrid from '~/components/CMS/PromotionsGrid/PromotionsGrid.vue';
import RecentlyViewedProducts from '~/components/CMS/RecentlyViewedProducts/RecentlyViewedProducts.vue';
import RecommendedProducts from '~/components/CMS/RecommendedProducts/RecommendedProducts.vue';
import ResponsiveImage from '~/components/CMS/ResponsiveImage/ResponsiveImage.vue';
import RichTextDisplay from '~/components/CMS/RichTextDisplay/RichTextDisplay.vue';
import SectionTitle from '~/components/CMS/SectionTitle.vue';
import ShopAllCategories from '~/components/CMS/ShopAllCategories/ShopAllCategories.vue';
import StoryblokAccordion from '~/components/CMS/StoryblokAccordion/StoryblokAccordion.vue';
import StoryblokImage from '~/components/CMS/StoryblokImage/StoryblokImage.vue';
import TwoColumn from '~/components/CMS/TwoColumn/TwoColumn.vue';
import VideoGrid from '~/components/CMS/VideoGrid/VideoGrid.vue';
import CustomerValueProposition from '~/components/CustomerValueProposition/CustomerValueProposition.vue';
import CustomerValuePropositionContainer from '~/components/CustomerValuePropositionContainer/CustomerValuePropositionContainer.vue';
import FeaturedArticle from '~/components/FeaturedArticle/FeaturedArticle.vue';
import StoresPicker from '~/components/Stores/StoresPicker.vue';

// import Placeholder from '~/components/Placeholder.vue';
Vue.component('CustomerValueProposition', CustomerValueProposition);
Vue.component('CustomerValuePropositionContainer', CustomerValuePropositionContainer);
Vue.component('BannerCarousel', BannerCarousel);
Vue.component('BannerImage', BannerImage);

Vue.component('RichTextEditor', RichTextDisplay);
Vue.component('RichTextDisplay', RichTextDisplay);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Carousel', HeroCarousel);
Vue.component('ContentCards', ContentCards);
Vue.component('HeroImageTitle', HeroImageTitle);
Vue.component('HeroTitle', HeroTitle);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Author', Author);
Vue.component('BrandHero', BrandHero);
Vue.component('ProductCarouselAuto', ProductCarouselAuto);
Vue.component('BrandCarousel', BrandCarousel);
Vue.component('CategoryCarousel', CategoryCarousel);
Vue.component('PopularCategoriesCarousel', PopularCategoriesCarousel);
Vue.component('PromotionsGrid', PromotionsGrid);
Vue.component('FeaturedPromotion', FeaturedPromotion);
Vue.component('PromotionContent', PromotionContent);
Vue.component('TwoColumn', TwoColumn);
Vue.component('StoryblokImage', StoryblokImage);
Vue.component('SectionTitle', SectionTitle);
Vue.component('PageTitle', PageTitle);
Vue.component('AnchorLinkedBlocks', AnchorLinkedBlocks);
Vue.component('StoryblokAccordion', StoryblokAccordion);
Vue.component('ShopAllCategories', ShopAllCategories);
Vue.component('StoresPicker', StoresPicker);
Vue.component('VideoGrid', VideoGrid);
Vue.component('RecommendedProducts', RecommendedProducts);
Vue.component('FeaturedArticle', FeaturedArticle);
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('KlaviyoFormEmbed', KlaviyoFormEmbed);
Vue.component('LegendaryHeroBlock', LegendaryHeroBlock);
Vue.component('LegendaryGrid', LegendaryGrid);
Vue.component('RecentlyViewedProducts', RecentlyViewedProducts);

import { Cart, CartResponse } from './useBuyerCart.types';

const mapToCart = (cartResponse: CartResponse): Cart => {
  if (!cartResponse.id) {
    throw new Error('Missing cart ID from response.');
  }

  return {
    id: cartResponse.id,
    totalQuantity: cartResponse.totalQuantity || 0,
    subtotalAmount: cartResponse.cost?.subtotalAmount?.amount || 0,
    checkoutUrl: cartResponse.checkoutUrl || '',
    lines:
      cartResponse.lines?.edges?.map((edge) => {
        const merchandise = edge.node?.merchandise;

        return {
          cartLineId: edge.node?.id || '',
          id: merchandise?.id || '',
          sku: merchandise?.sku || '',
          title: merchandise?.product?.title || '',
          compareAtPriceV2: merchandise?.compareAtPriceV2?.amount || 0,
          priceV2: merchandise?.priceV2?.amount || 0,
          productVendor: merchandise?.product?.vendor || '',
          partNumber: merchandise?.partNumber?.value || '',
          totalAmount: edge.node?.cost?.amountPerQuantity?.amount || 0,
          imageSrc: merchandise?.image?.src || '',
          quantity: edge.node?.quantity || 0,
          totalInventory: merchandise?.product?.totalInventory || 0,
          availableForSale: merchandise?.availableForSale || false,
          selectedOptions:
            merchandise?.selectedOptions?.map((option) => ({
              name: option.name || '',
              value: option.value || '',
            })) || [],
        };
      }) || [],
  };
};

export default mapToCart;

import apiClient from '~/api/apiClient';

import { RenewTokenResponse } from './renewToken.types';

export interface GetRenewTokenProps {
  clientId: string;
  refreshToken: string;
  accessTokenEndpoint: string;
}

const generateRequestBody = ({
  clientId,
  refreshToken,
}: Omit<GetRenewTokenProps, 'accessTokenEndpoint'>) => {
  const body = new URLSearchParams();
  body.append('grant_type', 'refresh_token');
  body.append('client_id', clientId);
  body.append('refresh_token', refreshToken);

  return body;
};

const getRenewedTokens = async ({
  clientId,
  refreshToken,
  accessTokenEndpoint,
}: GetRenewTokenProps) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const body = generateRequestBody({ clientId, refreshToken });

  const response = await apiClient<RenewTokenResponse>({
    url: accessTokenEndpoint,
    body: body.toString(),
    headers,
    method: 'POST',
  });

  return response;
};

export default getRenewedTokens;

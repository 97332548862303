import sanitizeLinkUrl from '~/helpers/sanitizeLinkUrl';

import { ActiveNavigationMenuItemProps, MenuItemLink } from './useMenuItemLink.types';
import { isMenuShopifyCategoryItemLink } from './useMenuItemLink.utils';
import useMenuItemShopifyCategoryLink from './useMenuItemShopifyCategoryLink/useMenuItemShopifyCategoryLink';

interface UseMenuItemLinkProps {
  link: MenuItemLink[];
  activeNavigationMenuItems?: ActiveNavigationMenuItemProps[];
}

export interface UseMenuItemLinkReturnProps {
  linkUrl: string;
}

const getLinkUrl = (
  link: MenuItemLink,
  activeNavigationMenuItems: ActiveNavigationMenuItemProps[],
) => {
  if (!link) {
    return '/';
  }

  if (isMenuShopifyCategoryItemLink(link)) {
    const { linkUrl: shopifyCategoryLinkUrl } = useMenuItemShopifyCategoryLink({
      link: link,
      activeNavigationMenuItems,
    });

    return sanitizeLinkUrl(shopifyCategoryLinkUrl);
  }

  return sanitizeLinkUrl(link.link?.cached_url || '');
};

const useMenuItemLink = ({
  link,
  activeNavigationMenuItems = [],
}: UseMenuItemLinkProps): UseMenuItemLinkReturnProps => {
  const linkUrl = getLinkUrl(link[0], activeNavigationMenuItems);

  return {
    linkUrl,
  };
};

export default useMenuItemLink;

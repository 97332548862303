import {
  getPreAuthFromStorage,
  removePreAuthFromStorage,
  saveAuthTokensToStorage,
} from '../useAuth.storage';
import exchangeCodeForTokens from './exchangeCodeForTokens';
import mapToAuthTokens from './mapToAuthTokens';

export interface AuthenticateProps {
  code: string;
  state: string;
  clientId: string;
  callbackURL: string;
  accessTokenEndpoint: string;
}

const authenticate = async ({
  code,
  state,
  clientId,
  callbackURL,
  accessTokenEndpoint,
}: AuthenticateProps) => {
  const preAuth = getPreAuthFromStorage();

  if (!preAuth?.codeVerifier) {
    throw new Error('Missing code verifier.');
  }

  if (preAuth.state !== state) {
    throw new Error('State has been tampered with.');
  }

  const accessTokenResponse = await exchangeCodeForTokens({
    code,
    clientId,
    callbackURL,
    codeVerifier: preAuth.codeVerifier,
    accessTokenEndpoint,
  });

  if (accessTokenResponse) {
    const authTokens = mapToAuthTokens(accessTokenResponse);

    saveAuthTokensToStorage(authTokens);
    removePreAuthFromStorage();

    return authTokens;
  }

  throw new Error('Failed to retrieve access token.');
};

export default authenticate;

const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['checkout'] = require('../middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['lowercase'] = require('../middleware/lowercase.js')
middleware['lowercase'] = middleware['lowercase'].default || middleware['lowercase']

middleware['removeTrailingSlash'] = require('../middleware/removeTrailingSlash.js')
middleware['removeTrailingSlash'] = middleware['removeTrailingSlash'].default || middleware['removeTrailingSlash']

middleware['renewToken'] = require('../middleware/renewToken.ts')
middleware['renewToken'] = middleware['renewToken'].default || middleware['renewToken']

export default middleware

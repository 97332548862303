import { load, remove, save } from '~/helpers/localStorage';

import { PRE_ACCESS_IDENTIFIER, SESSION_IDENTIFIER } from './useAuth.constants';
import { AuthTokens, PreAuth } from './useAuth.types';

export const getAuthTokensFromStorage = (): AuthTokens | null => {
  return load<AuthTokens>(SESSION_IDENTIFIER);
};

export const saveAuthTokensToStorage = (authTokens: AuthTokens) => {
  save<AuthTokens>(SESSION_IDENTIFIER, authTokens);
};

export const removeAuthTokensFromStorage = () => {
  remove(SESSION_IDENTIFIER);
};

export const getPreAuthFromStorage = () => {
  return load<PreAuth>(PRE_ACCESS_IDENTIFIER);
};

export const savePreAuthToStorage = (preAuth: PreAuth) => {
  save<PreAuth>(PRE_ACCESS_IDENTIFIER, preAuth);
};

export const removePreAuthFromStorage = () => {
  remove(PRE_ACCESS_IDENTIFIER);
};

import { shopifyCategoryGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

import {
  ActiveNavigationMenuItemProps,
  MenuShopifyCategoryItemLink,
} from '../useMenuItemLink.types';
import transformShopifyCategoryHandle from './transformShopifyCategoryHandle';

interface UseMenuItemShopifyCategoryLinkProps {
  link: MenuShopifyCategoryItemLink;
  activeNavigationMenuItems: ActiveNavigationMenuItemProps[];
}

export interface UseMenuItemShopifyCategoryLinkReturnProps {
  linkUrl: string;
}

const useMenuItemShopifyCategoryLink = ({
  link,
  activeNavigationMenuItems,
}: UseMenuItemShopifyCategoryLinkProps): UseMenuItemShopifyCategoryLinkReturnProps => {
  const linkUrl = transformShopifyCategoryHandle({
    handle: shopifyCategoryGetters.getHandle(link) || '',
    activeNavigationMenuItems,
  });

  return {
    linkUrl,
  };
};

export default useMenuItemShopifyCategoryLink;

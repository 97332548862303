import apiClient from '~/api/apiClient';

import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartLineInput, CartResponse } from './useBuyerCart.types';

export interface CartLinesAddProps {
  cartEndpoint: string;
  cartId: string;
  lines: CartLineInput[];
  shopifyStorefrontToken: string;
}

export interface CartLinesAddResponse {
  data?: {
    cartLinesAdd?: {
      cart?: CartResponse;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateMutation = ({ cartId, lines }: Pick<CartLinesAddProps, 'cartId' | 'lines'>) => {
  return JSON.stringify({
    query: `
        mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
                cart {
                  ${cartQuery}
                }
            }
        }
    `,
    variables: { cartId, lines },
  });
};

const verifyResponse = (response: CartLinesAddResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to add cart.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cartLinesAdd?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cartLinesAdd.cart);
};

const cartLinesAdd = async ({
  cartId,
  lines,
  cartEndpoint,
  shopifyStorefrontToken,
}: CartLinesAddProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<CartLinesAddResponse>({
    url: cartEndpoint,
    body: generateMutation({ cartId, lines }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default cartLinesAdd;

import apiClient from '~/api/apiClient';

import { AuthTokens } from '../useAuth.types';
import { AccessTokenResponse } from './authenticate.types';

export interface ExchangeCodeForTokensProps {
  code: string;
  clientId: string;
  callbackURL: string;
  codeVerifier: string;
  accessTokenEndpoint: string;
}

export interface ExchangeCodeForTokensReturnProps {
  auth: AuthTokens | null;
}

const generateTokenRequestBody = ({
  clientId,
  callbackURL,
  code,
  codeVerifier,
}: Omit<ExchangeCodeForTokensProps, 'accessTokenEndpoint'>) => {
  const body = new URLSearchParams();
  body.append('grant_type', 'authorization_code');
  body.append('client_id', clientId);
  body.append('redirect_uri', callbackURL);
  body.append('code', code);
  body.append('code_verifier', codeVerifier);

  return body;
};

const exchangeCodeForTokens = async ({
  code,
  clientId,
  callbackURL,
  codeVerifier,
  accessTokenEndpoint,
}: ExchangeCodeForTokensProps): Promise<AccessTokenResponse | undefined> => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const body = generateTokenRequestBody({ code, clientId, callbackURL, codeVerifier });

  const response = await apiClient<AccessTokenResponse>({
    url: accessTokenEndpoint,
    body: body.toString(),
    headers,
    method: 'POST',
  });

  return response;
};

export default exchangeCodeForTokens;

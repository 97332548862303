import apiClient from '~/api/apiClient';

import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartLineUpdateInput, CartResponse } from './useBuyerCart.types';

export interface CartLinesUpdateProps {
  cartEndpoint: string;
  cartId: string;
  lines: CartLineUpdateInput[];
  shopifyStorefrontToken: string;
}

export interface CartLinesUpdateResponse {
  data?: {
    cartLinesUpdate?: {
      cart?: CartResponse;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateMutation = ({ cartId, lines }: Pick<CartLinesUpdateProps, 'cartId' | 'lines'>) => {
  return JSON.stringify({
    query: `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ${cartQuery}
          }
        }
      }
    `,
    variables: { cartId, lines },
  });
};

const verifyResponse = (response: CartLinesUpdateResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to update cart.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cartLinesUpdate?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cartLinesUpdate.cart);
};

const cartLinesUpdate = async ({
  cartId,
  lines,
  cartEndpoint,
  shopifyStorefrontToken,
}: CartLinesUpdateProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<CartLinesUpdateResponse>({
    url: cartEndpoint,
    body: generateMutation({ cartId, lines }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default cartLinesUpdate;

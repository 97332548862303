import apiClient from '~/api/apiClient';

import { BuyerIdentity } from '../useCustomer';
import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartResponse } from './useBuyerCart.types';

export interface CartBuyerIdentityUpdateProps {
  cartEndpoint: string;
  buyerIdentity: BuyerIdentity;
  cartId: string;
  shopifyStorefrontToken: string;
}

export interface CartBuyerIdentityUpdateResponse {
  data?: {
    cartBuyerIdentityUpdate?: {
      cart?: CartResponse;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateMutation = ({
  buyerIdentity,
  cartId,
}: Pick<CartBuyerIdentityUpdateProps, 'buyerIdentity' | 'cartId'>) => {
  return JSON.stringify({
    query: `
    mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
        cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
            cart {
              ${cartQuery}
            }
        }
    }
    `,
    variables: { buyerIdentity, cartId },
  });
};

const verifyResponse = (response: CartBuyerIdentityUpdateResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to update cart buyer identity.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cartBuyerIdentityUpdate?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cartBuyerIdentityUpdate.cart);
};

const cartBuyerIdentityUpdate = async ({
  buyerIdentity,
  cartEndpoint,
  cartId,
  shopifyStorefrontToken,
}: CartBuyerIdentityUpdateProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<CartBuyerIdentityUpdateResponse>({
    url: cartEndpoint,
    body: generateMutation({ buyerIdentity, cartId }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default cartBuyerIdentityUpdate;

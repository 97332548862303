import { CustomerDetailsResponse } from './getCustomerDetails';
import { Customer } from './useCustomer';

const mapToCustomer = (customerDetails: CustomerDetailsResponse): Customer => ({
  id: customerDetails.data?.customer?.id,
  firstName: customerDetails.data?.customer?.firstName,
  lastName: customerDetails.data?.customer?.lastName,
  displayName: customerDetails.data?.customer?.displayName,
  emailAddress: customerDetails.data?.customer?.emailAddress?.emailAddress,
  phoneNumber: customerDetails.data?.customer?.phoneNumber?.phoneNumber,
  companyLocationId:
    customerDetails.data?.customer?.companyContacts?.edges?.[0]?.node?.company?.locations
      ?.edges?.[0]?.node?.id,
  addresses:
    customerDetails.data?.customer?.addresses?.edges?.flatMap((edge) =>
      edge.node ? [edge.node] : [],
    ) || [],
  emailMarketingState: customerDetails.data?.customer?.emailAddress?.marketingState,
  defaultAddress: customerDetails.data?.customer?.addresses?.edges
    ?.flatMap((edge) => (edge.node ? [edge.node] : []))
    .find((address) => address.id === customerDetails.data?.customer?.defaultAddress?.id),
});

export default mapToCustomer;

import { Context } from '@nuxt/types';
import { Logger } from '@vue-storefront/core';
import isAfter from 'date-fns/isAfter';

import ApiResponseError from '~/api/ApiResponseError';
import {
  getAuthTokensFromStorage,
  removeAuthTokensFromStorage,
  renewToken,
  setAuthTokens,
} from '~/composables/useAuth';

export default async function ({ $config, from, route }: Context) {
  const isPageRefresh = from.fullPath === route.fullPath;

  const isCallbackPage = from.path === '/callback' && route.path === '/callback';
  const isLogoutPage = from.path === '/logout';

  if (!isPageRefresh || isCallbackPage || isLogoutPage) {
    return;
  }

  try {
    const authTokens = getAuthTokensFromStorage();

    if (authTokens) {
      const { expiresTimeInUtc, refreshToken } = authTokens;

      const isSessionExpired = isAfter(new Date(), new Date(expiresTimeInUtc));

      if (!isSessionExpired) {
        const renewedTokens = await renewToken({
          clientId: $config.clientId,
          refreshToken,
          accessTokenEndpoint: $config.accessTokenEndpoint,
        });

        setAuthTokens(renewedTokens);
      }
    }
  } catch (err) {
    if (err instanceof ApiResponseError && err.statusCode === 401) {
      Logger.error('Failed to renew tokens:', err.message);
      setAuthTokens(null);
      removeAuthTokensFromStorage();
    } else {
      Logger.error('Failed to renew tokens:', err instanceof Error ? err.message : err);
    }
  }
}

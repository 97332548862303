import { getAuthTokensFromStorage, saveAuthTokensToStorage } from '../useAuth.storage';
import getRenewedTokens from './getRenewedTokens';
import mapToAuthTokens from './mapToAuthTokens';

export interface RenewTokenProps {
  clientId: string;
  refreshToken: string;
  accessTokenEndpoint: string;
}

const renewToken = async ({ clientId, refreshToken, accessTokenEndpoint }: RenewTokenProps) => {
  const renewedTokenResponse = await getRenewedTokens({
    clientId,
    refreshToken,
    accessTokenEndpoint,
  });

  const authTokens = getAuthTokensFromStorage();

  if (!renewedTokenResponse) {
    throw new Error('Failed to retrieve updated access token.');
  }

  if (!authTokens) {
    throw new Error('Missing auth tokens while attempting to renew tokens.');
  }

  const renewedAuthTokens = mapToAuthTokens(renewedTokenResponse, authTokens);

  saveAuthTokensToStorage(renewedAuthTokens);

  return renewedAuthTokens;
};

export default renewToken;

import apiClient from '~/api/apiClient';

import { customerAddressQuery } from './customerAddressQuery';
import { CustomerAddress, CustomerAddressInput } from './useAddress.types';

export interface CustomerAddressUpdateProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
  address?: CustomerAddressInput;
  addressId: string;
  defaultAddress?: boolean;
}

export interface CustomerAddressUpdateResponse {
  data?: {
    customerAddressUpdate?: {
      customerAddress?: CustomerAddress;
      userErrors?: {
        message?: string;
      }[];
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = ({
  address,
  addressId,
  defaultAddress,
}: Pick<CustomerAddressUpdateProps, 'address' | 'addressId' | 'defaultAddress'>) => {
  return JSON.stringify({
    query: `
      mutation customerAddressUpdate($address: CustomerAddressInput, $addressId: ID!, $defaultAddress: Boolean) {
        customerAddressUpdate(address: $address, addressId: $addressId, defaultAddress: $defaultAddress) {
          customerAddress {
            ${customerAddressQuery}
          }
          userErrors {
            message
          }
        }
      }
    `,
    variables: { address, addressId, defaultAddress },
  });
};

const customerAddressUpdate = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
  address,
  addressId,
  defaultAddress,
}: CustomerAddressUpdateProps): Promise<CustomerAddressUpdateResponse | undefined> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CustomerAddressUpdateResponse>({
    url: customerAccountEndpoint,
    body: generateQuery({ address, addressId, defaultAddress }),
    headers,
    method: 'POST',
  });

  if (response?.errors && response.errors.length > 0) {
    throw new Error(response.errors.map((error) => error.message).join('; '));
  }

  if (
    response?.data?.customerAddressUpdate?.userErrors &&
    response.data.customerAddressUpdate.userErrors.length > 0
  ) {
    throw new Error(
      response.data.customerAddressUpdate.userErrors.map((error) => error.message).join('; '),
    );
  }

  return response;
};

export default customerAddressUpdate;

import { computed, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

import useAuth from '../useAuth';
import { addLeadingZeroOnSingleDigit, getRemainingSessionTime } from './useSession.util';

interface SessionTime {
  minutes: number | null;
  seconds: number | null;
}

// eslint-disable-next-line max-lines-per-function
const useSession = () => {
  const remainingSessionTime = ref<SessionTime>({ minutes: null, seconds: null });
  let intervalId: ReturnType<typeof setInterval>;

  const { expiresTime, error } = useAuth();

  const isSessionExpired = computed(() => {
    if (error.value.renewToken) return true;

    const { minutes, seconds } = remainingSessionTime.value;

    if (minutes === null || seconds === null) return false;

    return minutes <= 0 && seconds <= 0;
  });

  const isSessionAboutToExpire = computed(() => {
    const { minutes } = remainingSessionTime.value;

    return !isSessionExpired.value && minutes !== null && minutes < 2;
  });

  const clearSessionInterval = () => {
    if (intervalId) clearInterval(intervalId);
  };

  const updateSessionTime = () => {
    if (!expiresTime.value || isSessionExpired.value) {
      clearSessionInterval();
    } else {
      remainingSessionTime.value = getRemainingSessionTime(expiresTime.value);
    }
  };

  const startSessionTimer = () => {
    clearSessionInterval(); // Ensure there's no existing interval running
    intervalId = setInterval(updateSessionTime, 1000);
  };

  onMounted(startSessionTimer);

  onUnmounted(clearSessionInterval);

  const displayedRemainingSessionTime = computed(() => ({
    minutes: addLeadingZeroOnSingleDigit(remainingSessionTime.value.minutes),
    seconds: addLeadingZeroOnSingleDigit(remainingSessionTime.value.seconds),
  }));

  return {
    isSessionAboutToExpire,
    isSessionExpired,
    remainingSessionTime: displayedRemainingSessionTime,
  };
};

export default useSession;

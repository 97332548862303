// TODO: Move this type to Shopify pkg
import { BuyerIdentity } from '../useCustomer';
import cartCreate from './cartCreate';
import getCart from './getCart';
import { getCartFromStorage, saveCartToStorage } from './useBuyerCart.storage';

export interface LoadCartProps {
  cartEndpoint: string;
  shopifyStorefrontToken: string;
  buyer: BuyerIdentity | null;
}

const loadCart = async ({ cartEndpoint, shopifyStorefrontToken, buyer }: LoadCartProps) => {
  const cartFromStorage = getCartFromStorage();
  const existingCartId = cartFromStorage?.id;

  if (existingCartId) {
    return await getCart({
      cartId: existingCartId,
      cartEndpoint,
      shopifyStorefrontToken,
    });
  } else {
    const cart = await cartCreate({
      buyerIdentity: buyer,
      cartEndpoint,
      shopifyStorefrontToken,
    });

    saveCartToStorage({ id: cart.id });

    return cart;
  }
};

export default loadCart;

import { shopifyCategoryGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

import {
  CATEGORY_ROUTE_PREFIX,
  SEARCH_CATEGORY_TENANT_PREFIX_HANDLE,
} from '~/composables/useUiHelpers';

import { ActiveNavigationMenuItemProps } from '../useMenuItemLink.types';
import { isMenuShopifyCategoryItemLink } from '../useMenuItemLink.utils';

export const getHandleDifference = (handle: string, parentHandle: string) => {
  return handle.replace(parentHandle, '').replace(/^-/, '');
};

const sanitizeHandle = (handle: string) => {
  return handle.replace(SEARCH_CATEGORY_TENANT_PREFIX_HANDLE, `${CATEGORY_ROUTE_PREFIX}/`);
};

/**
 * 1. Loop through the active navigation menu items
 * 2. Find the difference between two handles e.g. rare-spares-categories-ford and rare-spares-categories-ford-xd-falcon-badges,
 * the difference is "xd-falcon-badges", split those two by "/", so it becomes rare-spares-categories-ford/xd-falcon-badges
 */
const transformShopifyCategoryHandle = ({
  handle,
  activeNavigationMenuItems,
}: {
  handle: string;
  activeNavigationMenuItems: ActiveNavigationMenuItemProps[];
}): string => {
  const transformedHandle = [...activeNavigationMenuItems].reverse().reduce((acc, item) => {
    const link = item.navigationMenuItem.link?.[0];

    if (link && isMenuShopifyCategoryItemLink(link)) {
      const otherHandles = shopifyCategoryGetters.getHandle(link) || '';

      const handleDifference = getHandleDifference(acc, otherHandles);

      return `${otherHandles}/${handleDifference}`;
    }

    return acc;
  }, handle);

  return sanitizeHandle(transformedHandle);
};

export default transformShopifyCategoryHandle;

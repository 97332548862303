import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

import { BuyerIdentity } from '../useCustomer';
import cartBuyerIdentityUpdate from './cartBuyerIdentityUpdate';
import cartLinesAdd from './cartLinesAdd';
import cartLinesRemove from './cartLinesRemove';
import cartLinesUpdate from './cartLinesUpdate';
import loadCart from './loadCart';
import { getCartFromStorage } from './useBuyerCart.storage';
import { Cart } from './useBuyerCart.types';

interface UseBuyerCartErrors {
  loadCart: string | null;
  addLines: string | null;
  updateLines: string | null;
  removeLines: string | null;
  cartBuyerIdentityUpdate: string | null;
}

const cart = ref<Cart | null>(null);

// eslint-disable-next-line max-lines-per-function
const useBuyerCart = () => {
  const { $config } = useContext();
  const error = ref<UseBuyerCartErrors>({
    loadCart: null,
    addLines: null,
    updateLines: null,
    removeLines: null,
    cartBuyerIdentityUpdate: null,
  });
  const isLoading = ref(false);

  const handleLoadCart = async (buyer: BuyerIdentity | null = null) => {
    try {
      isLoading.value = true;
      cart.value = await loadCart({
        cartEndpoint: $config.cartEndpoint,
        buyer,
        shopifyStorefrontToken: $config.shopifyStorefrontToken,
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load cart';
      Logger.error(errorMessage);
      error.value = {
        ...error.value,
        loadCart: errorMessage,
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleCartBuyerIdentityUpdate = async (buyer: BuyerIdentity) => {
    try {
      isLoading.value = true;
      const cartStorage = getCartFromStorage();

      if (cartStorage?.id) {
        cart.value = await cartBuyerIdentityUpdate({
          buyerIdentity: buyer,
          cartEndpoint: $config.cartEndpoint,
          cartId: cartStorage.id,
          shopifyStorefrontToken: $config.shopifyStorefrontToken,
        });
      } else {
        throw new Error('Missing cart ID.');
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'Failed to update cart buyer identity.';
      Logger.error(errorMessage);
      error.value = {
        ...error.value,
        cartBuyerIdentityUpdate: errorMessage,
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleUpdateItemQty = async ({
    id,
    merchandiseId,
    quantity,
  }: {
    id: string;
    merchandiseId: string;
    quantity: number;
  }) => {
    try {
      isLoading.value = true;
      const cartStorage = getCartFromStorage();

      if (cartStorage?.id) {
        cart.value = await cartLinesUpdate({
          cartId: cartStorage.id,
          lines: [{ id, merchandiseId, quantity }],
          cartEndpoint: $config.cartEndpoint,
          shopifyStorefrontToken: $config.shopifyStorefrontToken,
        });
      } else {
        throw new Error('Missing cart ID.');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to add cart lines.';
      Logger.error(errorMessage);
      error.value = {
        ...error.value,
        updateLines: errorMessage,
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleAddCartLine = async ({
    merchandiseId,
    quantity,
    attributes,
  }: {
    merchandiseId: string;
    quantity: number;
    attributes?: { key: string; value: string }[];
  }) => {
    try {
      isLoading.value = true;
      const cartStorage = getCartFromStorage();

      if (cartStorage?.id) {
        cart.value = await cartLinesAdd({
          cartId: cartStorage.id,
          lines: [{ merchandiseId, quantity, attributes }],
          cartEndpoint: $config.cartEndpoint,
          shopifyStorefrontToken: $config.shopifyStorefrontToken,
        });
      } else {
        throw new Error('Missing cart ID.');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to add cart lines.';
      Logger.error(errorMessage);
      error.value = {
        ...error.value,
        addLines: errorMessage,
      };
    } finally {
      isLoading.value = false;
    }
  };

  const handleRemoveCartLines = async (lineIds: string[]) => {
    try {
      isLoading.value = true;
      const cartStorage = getCartFromStorage();

      if (cartStorage?.id) {
        cart.value = await cartLinesRemove({
          cartId: cartStorage.id,
          lineIds,
          cartEndpoint: $config.cartEndpoint,
          shopifyStorefrontToken: $config.shopifyStorefrontToken,
        });
      } else {
        throw new Error('Missing cart ID.');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to remove cart lines.';
      Logger.error(errorMessage);
      error.value = {
        ...error.value,
        removeLines: errorMessage,
      };
    } finally {
      isLoading.value = false;
    }
  };

  return {
    cart,
    error,
    load: handleLoadCart,
    updateItemQty: handleUpdateItemQty,
    addCartLine: handleAddCartLine,
    cartBuyerIdentityUpdate: handleCartBuyerIdentityUpdate,
    removeCartLines: handleRemoveCartLines,
    isLoading,
  };
};

export default useBuyerCart;

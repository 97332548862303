import { computed, ref, useContext, useRouter } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

import authenticate from './authenticate';
import login from './login';
import logout from './logout';
import renewToken from './renewToken/renewToken';
import { getAuthTokensFromStorage, removeAuthTokensFromStorage } from './useAuth.storage';
import { AuthTokens } from './useAuth.types';

export interface UseAuthErrors {
  authenticate: string | null;
  renewToken: string | null;
}

const authTokens = ref<AuthTokens | null>(getAuthTokensFromStorage());

export const setAuthTokens = (tokens: AuthTokens | null) => {
  authTokens.value = tokens;
};

window.addEventListener('focus', () => {
  authTokens.value = getAuthTokensFromStorage();
});

// eslint-disable-next-line max-lines-per-function
const useAuth = () => {
  const router = useRouter();
  const { $config } = useContext();

  const error = ref<UseAuthErrors>({ authenticate: null, renewToken: null });

  const accessToken = computed(() => authTokens.value?.accessToken);

  const isLoggedIn = computed(() => !!accessToken.value);

  const expiresTime = computed(() =>
    authTokens.value?.expiresTimeInUtc ? new Date(authTokens.value.expiresTimeInUtc) : null,
  );

  const setError = (key: keyof UseAuthErrors, message: string) => {
    error.value = { ...error.value, [key]: message };
  };

  const handleLogin = async () => {
    await login({
      clientId: $config.clientId,
      callbackURL: $config.callbackURL,
      authorizationEndpoint: $config.authorizationEndpoint,
    });
  };

  const handleLogout = async () => {
    await logout({
      logoutEndpoint: $config.logoutEndpoint,
      idToken: authTokens.value?.idToken || '',
      redirectUri: $config.logoutCallbackURL,
    });
  };

  const redirectToHomePage = async () => await router.push({ path: '/' });

  const handleAuthenticate = async (code: string, state: string) => {
    try {
      const tokens = await authenticate({
        clientId: $config.clientId,
        callbackURL: $config.callbackURL,
        accessTokenEndpoint: $config.accessTokenEndpoint,
        code,
        state,
      });

      if (tokens) {
        authTokens.value = tokens;

        await redirectToHomePage();
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Failed to authenticate';
      Logger.error('authenticate', errorMessage);
      setError('authenticate', errorMessage);
    }
  };

  const handleRenewToken = async () => {
    error.value.renewToken = null; // Reset error

    try {
      const refreshToken = authTokens.value?.refreshToken;

      if (!refreshToken) {
        throw new Error('Missing refresh token.');
      }

      const newTokens = await renewToken({
        clientId: $config.clientId,
        refreshToken,
        accessTokenEndpoint: $config.accessTokenEndpoint,
      });

      if (newTokens) {
        authTokens.value = newTokens;
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Failed to renew token';
      Logger.error('renewToken', errorMessage);
      setError('renewToken', errorMessage);
    }
  };

  const postLogout = () => {
    authTokens.value = null;
    removeAuthTokensFromStorage();
  };

  return {
    login: handleLogin,
    logout: handleLogout,
    postLogout,
    isLoggedIn,
    authenticate: handleAuthenticate,
    renewToken: handleRenewToken,
    expiresTime,
    error,
    accessToken,
  };
};

export default useAuth;

import { savePreAuthToStorage } from '../useAuth.storage';
import { generateCodeVerifier, generateState } from '../useAuth.util';
import generateLoginUrl from './generateLoginUrl';

export interface LoginProps {
  clientId: string;
  callbackURL: string;
  authorizationEndpoint: string;
}

const login = async ({ clientId, callbackURL, authorizationEndpoint }: LoginProps) => {
  const codeVerifier = generateCodeVerifier();
  const state = generateState();

  const loginUrl = await generateLoginUrl({
    clientId,
    callbackURL,
    authorizationEndpoint,
    codeVerifier,
    state,
  });

  savePreAuthToStorage({ codeVerifier, state });

  window.location.replace(loginUrl);
};

export default login;

import { generateCodeChallenge, generateNonce } from '../useAuth.util';

export interface GenerateLoginUrlProps {
  clientId: string;
  callbackURL: string;
  authorizationEndpoint: string;
  codeVerifier: string;
  state: string;
}

const generateLoginUrl = async ({
  clientId,
  callbackURL,
  authorizationEndpoint,
  codeVerifier,
  state,
}: GenerateLoginUrlProps) => {
  const authorizationRequestUrl = new URL(authorizationEndpoint);

  const challenge = await generateCodeChallenge(codeVerifier);

  const params = {
    scope: 'openid email https://api.customers.com/auth/customer.graphql',
    client_id: clientId,
    response_type: 'code',
    redirect_uri: callbackURL,
    state,
    nonce: generateNonce(14),
    code_challenge: challenge,
    code_challenge_method: 'S256',
  };

  for (const [key, value] of Object.entries(params)) {
    authorizationRequestUrl.searchParams.append(key, value);
  }

  return authorizationRequestUrl.toString();
};

export default generateLoginUrl;

import { useRoute, watch } from '@nuxtjs/composition-api';

import useAuth from '../useAuth';
import { BuyerIdentity } from './useCustomer';

export interface UseFetchBuyerOnLoginProps {
  fetchBuyer: () => Promise<BuyerIdentity | undefined>;
}

const useFetchBuyerOnLogin = ({ fetchBuyer }: UseFetchBuyerOnLoginProps) => {
  const route = useRoute();
  const { isLoggedIn, accessToken } = useAuth();

  watch(
    accessToken,
    async () => {
      const isInLoggedOutPage = route.value.path === '/logout';

      if (isLoggedIn.value && !isInLoggedOutPage) {
        await fetchBuyer();
      }
    },
    { immediate: true },
  );
};

export default useFetchBuyerOnLogin;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardSection',{attrs:{"title":_vm.title,"description":_vm.description,"link":_vm.linkUrl,"link-text":_vm.link_text,"mobile":{
    cardMode: 'scroll',
    cardFlexBasis: 'calc(100%/3)',
    cardSpacing: '8px',
    peek: true,
  },"tablet":{
    cardFlexBasis: 'calc(100%/4)',
    showSteppers: true,
    peek: false,
    cardSpacing: '16px',
  },"desktop":{
    cardFlexBasis: 'calc(100%/5)',
    peek: false,
    cardSpacing: '32px',
  }}},_vm._l((_vm.categories),function(category,i){return _c('CategoryCardFlat',{key:i,attrs:{"title":category.content.name,"image-src":_vm.getStoryblokImageURL(category.content.thumb_image.filename, { width: 250 }),"image-alt":category.content.thumb_image.alt,"link":_vm.getCategoryLink(category)}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recentlyViewedProducts.length)?_c('div',{staticClass:"recently-viewed-products"},[_c('Container',[_c('CardSection',{staticClass:"recently-viewed-products__card-section",attrs:{"title":"Recently Viewed","mobile":{
        cardMode: 'scroll',
        showSteppers: false,
        cardSpacing: '8px',
        cardFlexBasis: 'calc(100%/2)',
      },"tablet":{
        cardMode: 'scroll',
        showSteppers: _vm.recentlyViewedProducts.length > 4,
        cardSpacing: '12px',
        cardFlexBasis: 'calc(100%/4)',
      },"desktop":{
        cardMode: 'scroll',
        showSteppers: _vm.recentlyViewedProducts.length > 6,
        cardSpacing: '16px',
        cardFlexBasis: 'calc(100%/6)',
      }}},_vm._l((_vm.recentlyViewedProducts),function(product,i){return _c('MiniProductCard',{key:i,staticClass:"recently-viewed-products__product-card",attrs:{"product-id":_vm.productGetters.getId(product),"variant-id":_vm.productGetters.getVariantId(product),"name":_vm.productGetters.getName(product),"sku":_vm.productGetters.getSKU(product),"part-number":_vm.productGetters.getProductVariantMetafield(product, 'partNumber'),"image":_vm.productGetters.getGallery(product)[0].normal,"vendor":_vm.productGetters.getVendorName(product),"price":_vm.productGetters.getProductPrice(product).price,"compare-to-price":_vm.productGetters.getProductPrice(product).compareToPrice,"quantity":_vm.productGetters.getStock(product),"has-variants":_vm.productGetters.hasVariants(product),"link":_vm.localePath(("/products/" + (_vm.productGetters.getSlug(product))))}})}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
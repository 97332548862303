import apiClient from '~/api/apiClient';

import { EmailMarketingState } from './useCustomer.types';

export interface CustomerEmailMarketingUnsubscribeProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
}

export interface CustomerEmailMarketingUnsubscribeDataResponse {
  emailAddress?: {
    emailAddress?: string;
    marketingState?: EmailMarketingState;
  };
}

export interface CustomerEmailMarketingUnsubscribeResponse {
  data?: {
    customerEmailMarketingUnsubscribe?: {
      userErrors?: {
        message?: string;
      }[];
    } & CustomerEmailMarketingUnsubscribeDataResponse;
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = () => {
  return JSON.stringify({
    query: `
      mutation customerEmailMarketingUnsubscribe {
        customerEmailMarketingUnsubscribe {
          emailAddress {
            emailAddress
            marketingState
          }
          userErrors {
            message
          }
        }
      }
    `,
  });
};

const customerEmailMarketingUnsubscribe = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
}: CustomerEmailMarketingUnsubscribeProps): Promise<
  CustomerEmailMarketingUnsubscribeDataResponse | undefined
> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CustomerEmailMarketingUnsubscribeResponse>({
    url: customerAccountEndpoint,
    body: generateQuery(),
    headers,
    method: 'POST',
  });

  if (response?.errors && response.errors.length > 0) {
    throw new Error(response.errors.map((error) => error.message).join('; '));
  }

  if (
    response?.data?.customerEmailMarketingUnsubscribe?.userErrors &&
    response.data.customerEmailMarketingUnsubscribe.userErrors.length > 0
  ) {
    throw new Error(
      response.data.customerEmailMarketingUnsubscribe.userErrors
        .map((error) => error.message)
        .join('; '),
    );
  }

  return response?.data?.customerEmailMarketingUnsubscribe;
};

export default customerEmailMarketingUnsubscribe;

import differenceInSeconds from 'date-fns/differenceInSeconds';

export const getRemainingSessionTime = (expiresTime: Date, currentTime = new Date()) => {
  const timeDifferenceInSeconds = differenceInSeconds(expiresTime, currentTime);

  return {
    minutes: Math.floor(timeDifferenceInSeconds / 60),
    seconds: timeDifferenceInSeconds % 60,
  };
};

export const addLeadingZeroOnSingleDigit = (time: number | null) => {
  if (time === null) {
    return null;
  }

  return time < 10 ? `0${time}` : `${time}`;
};

<template>
  <div class="sign-up-form">
    <BaseHeading :level="1" title="Sign up" class="heading" />

    <div class="form">
      <ValidationObserver v-if="!isSignUpSuccessModal" v-slot="{ handleSubmit }">
        <form autocomplete="off" @submit.prevent="handleSubmit(handleRegister)">
          <div class="signup-form__inputs">
            <p>First Name*</p>
            <ValidationProvider v-slot="{ errors }" rules="required|min:2">
              <BaseInput
                v-model="form.firstName"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="signup-form__input"
                placeholder="e.g. John"
                required
              />
            </ValidationProvider>
            <p>Last Name*</p>
            <ValidationProvider v-slot="{ errors }" rules="required|min:2">
              <BaseInput
                v-model="form.lastName"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="signup-form__input"
                placeholder="e.g. Doe"
                required
              />
            </ValidationProvider>
            <p>Email*</p>
            <ValidationProvider v-slot="{ errors }" rules="required|email">
              <BaseInput
                v-model="form.email"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="signup-form__input"
                type="email"
                placeholder="e.g. johndoe@email.com"
                required
              />
            </ValidationProvider>
            <p>Password*</p>
            <ValidationProvider v-slot="{ errors }" rules="required|passwordmin:5">
              <BaseInput
                v-model="form.password"
                :valid="!errors[0]"
                :error-message="errors[0]"
                class="signup-form__input"
                type="password"
                placeholder="Enter your password"
                required
              />
            </ValidationProvider>
          </div>
          <div v-if="error.register" class="signup-form__error">
            {{ error.register }}
          </div>
          <BaseButton
            type="submit"
            class="sf-button--full-width signup-form__sign-up-btn"
            :disabled="loading"
          >
            <BaseLoader class="signup-form__loader" :loading="loading"> Sign Up </BaseLoader>
          </BaseButton>
        </form>
      </ValidationObserver>
      <div v-else class="signup-notification">
        <h2 class="signup-notification-title">
          {{ notification.title }}
        </h2>
        <p class="signup-notification-message">
          {{ notification.message }}
        </p>
        <p v-if="notification.loginError" class="signup-notification-message signup-form__error">
          {{ notification.loginError }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, useRoute } from '@nuxtjs/composition-api';
import { BaseButton, BaseHeading, BaseInput, BaseLoader } from '@unified-commerce/gpc-ui-library';
import { useCart, useUser } from '@unified-commerce/gpc-vue-storefront-shopify';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, min, required } from 'vee-validate/dist/rules';

import { loginUser } from '../helpers/login/loginUser';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  validate: (value) => (value ?? '').trim().length >= 2,
  message: 'The field should have at least {length} characters',
});

extend('passwordmin', {
  ...min,
  validate: (value) => (value ?? '').trim().length > 5,
  message: 'Password must be at least 5 characters long',
});

export default defineComponent({
  name: 'SignupForm',
  components: {
    BaseHeading,
    BaseInput,
    BaseButton,
    BaseLoader,
    ValidationProvider,
    ValidationObserver,
  },
  setup(_props, context) {
    const cookies = context.root.$cookies;
    const form = ref({});
    const route = useRoute();
    const isLoginLoading = ref(false);
    const userEmail = ref('');
    const { cart } = useCart();
    const isSignUpSuccessModal = ref(false);
    const notification = ref({
      title: '',
      message: '',
      loginError: '',
    });

    const { user, register, login, load: loadUser, loading, error: userError } = useUser();

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    const handleRegister = async () => {
      resetErrorValues();
      await register({ user: form.value });

      const hasUserRegistrationErrors = userError.value.register || user.value.error;
      if (hasUserRegistrationErrors && userError.value.register) {
        error.register =
          "Sorry we couldn't process your request at this time. Please try again later";
        return;
      } else if (hasUserRegistrationErrors && user.value.error) {
        error.register = user.value.error;
        return;
      }

      await loadUser();

      notification.value.title = 'Success!';
      notification.value.message =
        'You have successfully signed up, welcome to the Rare Spares crew. We have sent you a welcome email';
      isSignUpSuccessModal.value = true;

      context.emit('register-success');
      await userLogin();
    };

    const userLogin = async () => {
      resetErrorValues();

      await loginUser(
        isLoginLoading,
        route,
        login,
        ref({ username: form.value.email, password: form.value.password }),
        cookies,
        user,
        userError,
        error,
        cart,
        context.root.$config.appIdentifier,
      );

      if (error.login) {
        notification.value.loginError =
          "Sorry we couldn't process your login request at this time. Please try again later";
      } else if (user.value.multipass) {
        window.location.href = user.value.multipass.url;
      } else {
        isLoginLoading.value = false;
        // in case of signup page, this redirects to home and in case of popup, it toggles the modal
        context.emit('internal-login-success');
        window.location.reload();
      }
    };

    return {
      form,
      error,
      userError,
      loading,
      isLoginLoading,
      handleRegister,
      userLogin,
      userEmail,
      isSignUpSuccessModal,
      notification,
    };
  },
});
</script>

<style lang="scss" scoped>
.sign-up-form {
  width: 100%;
}

.heading {
  --heading-title-font-size: 36px;
  --heading-title-line-height: 40px;
  --heading-title-margin-bottom: 8px;
  --heading-description-font-size: 12px;
  --heading-description-line-height: 16px;
  --heading-description-margin: 0;
  margin-bottom: 16px;

  & h1 {
    font-weight: 700;
    line-height: 40px;
  }

  @include md-devices-min {
    --heading-title-font-size: 40px;
    --heading-title-line-height: 44px;
    --heading-description-font-size: 16px;
    --heading-description-line-height: 24px;
    margin-bottom: 48px;
  }
}

.signup-notification {
  padding: var(--spacer-xl) var(--spacer-3base);

  &-title {
    font-size: var(--signup-notification-page-title, var(--font-size--xl));
    text-align: center;
  }

  &-message {
    font-size: var(--signup-notification-page-message, var(--font-size--lg));
    text-align: center;
    color: var(--c-gray);
  }
}

.signup-form {
  &__error {
    color: var(--c-support-50);
    margin-bottom: var(--spacer-xs);
  }

  &__inputs {
    @include md-devices-min {
      margin-bottom: 45px;
    }

    p {
      font-weight: 400;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      @include md-devices-min {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
      }
    }
  }

  &__sign-up-btn {
    margin-top: var(--spacer-base);
    --button-text-transform: capitalize;
    --button-background: var(--c-black);
    --button-padding: 14px 20px;
    --button-font-size: 18px;
    --button-line-height: 20px;
    --button-font-weight: 700;
  }

  &__loader {
    --loader-spinner-stroke: var(--c-light-variant);
  }
}

.form {
  width: 100%;
}

::v-deep .sf-input__error-message {
  color: var(--c-support-50);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
</style>

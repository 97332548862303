interface GenerateLogoutUrlProps {
  logoutEndpoint: string;
  idToken: string;
  redirectUri: string;
}

const generateLogoutUrl = ({ logoutEndpoint, idToken, redirectUri }: GenerateLogoutUrlProps) => {
  const logoutUrl = new URL(logoutEndpoint);

  logoutUrl.searchParams.append('id_token_hint', idToken);
  logoutUrl.searchParams.append('post_logout_redirect_uri', redirectUri);

  return logoutUrl.toString();
};

export default generateLogoutUrl;

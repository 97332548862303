import { SESSION_IDENTIFIER } from '~/composables/useAuth';

export default (context) => {
  const authTokens = window && window.localStorage.getItem(SESSION_IDENTIFIER);

  // check if user not logged In
  if (!authTokens) {
    context.app.router.push('/');
    context.redirect('/');
  }
};

import apiClient from '~/api/apiClient';

import cartQuery from './cartQuery';
import mapToCart from './mapToCart';
import { Cart, CartResponse } from './useBuyerCart.types';

export interface CartLinesRemoveProps {
  cartEndpoint: string;
  cartId: string;
  lineIds: string[];
  shopifyStorefrontToken: string;
}

export interface CartLinesRemoveResponse {
  data?: {
    cartLinesRemove?: {
      cart?: CartResponse;
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateMutation = ({
  cartId,
  lineIds,
}: Pick<CartLinesRemoveProps, 'cartId' | 'lineIds'>) => {
  return JSON.stringify({
    query: `
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            ${cartQuery}
          }
        }
      }
    `,
    variables: { cartId, lineIds },
  });
};

const verifyResponse = (response: CartLinesRemoveResponse | undefined): Cart => {
  if (!response) {
    throw new Error('Failed to remove cart.');
  }

  if (Array.isArray(response?.errors)) {
    throw new Error(response?.errors[0].message);
  }

  if (!response.data?.cartLinesRemove?.cart) {
    throw new Error('Missing cart response.');
  }

  return mapToCart(response.data.cartLinesRemove.cart);
};

const cartLinesRemove = async ({
  cartId,
  lineIds,
  cartEndpoint,
  shopifyStorefrontToken,
}: CartLinesRemoveProps): Promise<Cart> => {
  const headers = {
    'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
  };

  const response = await apiClient<CartLinesRemoveResponse>({
    url: cartEndpoint,
    body: generateMutation({ cartId, lineIds }),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default cartLinesRemove;

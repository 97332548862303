const cartProductVariant = `
    ... on ProductVariant {
        id
        sku
        availableForSale
        compareAtPriceV2 {
            amount
        }
        priceV2 {
            amount
        }
        product {
            title
            totalInventory
            vendor
        }
        partNumber: metafield(namespace: "plytix", key: "part_number") {
            value
        }
        image {
            src
        }
        selectedOptions {
            name
            value
        }
    }
`;

const cartQuery = `
    id
    totalQuantity
    checkoutUrl
    cost {
        subtotalAmount {
            amount
        }
    }
    lines(first: 200) {
        edges {
            node {
                merchandise {
                  ${cartProductVariant}
                }
                id
                quantity
                cost {
                    amountPerQuantity {
                        amount
                    }
                }
            }
        }
    }
`;

export default cartQuery;

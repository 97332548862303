export const customerAddressQuery = `
    id
    firstName
    lastName
    company
    formattedArea
    address1
    address2
    city
    province
    zip
    country
    phoneNumber
    zoneCode
`;

import apiClient from '~/api/apiClient';

import { customerAddressQuery } from './customerAddressQuery';
import { CustomerAddressInput } from './useAddress.types';

export interface CustomerAddressCreateProps {
  customerScopedAccessToken: string;
  customerAccountEndpoint: string;
  address: CustomerAddressInput;
  defaultAddress?: boolean;
}

export interface CustomerAddressCreateResponse {
  data?: {
    customerAddressCreate?: {
      customerAccessToken?: string;
      userErrors?: {
        message?: string;
      }[];
    };
  };
  errors?: {
    message?: string;
  }[];
}

const generateQuery = ({
  address,
  defaultAddress,
}: Pick<CustomerAddressCreateProps, 'address' | 'defaultAddress'>) => {
  return JSON.stringify({
    query: `
        mutation customerAddressCreate($address: CustomerAddressInput!) {
          customerAddressCreate(address: $address) {
            customerAddress {
              ${customerAddressQuery}    
            }
            userErrors {
              message
            }
          }
        }
    `,
    variables: { address, defaultAddress },
  });
};

const customerAddressCreate = async ({
  customerScopedAccessToken,
  customerAccountEndpoint,
  address,
  defaultAddress,
}: CustomerAddressCreateProps): Promise<CustomerAddressCreateResponse | undefined> => {
  const headers = {
    Authorization: customerScopedAccessToken,
  };

  const response = await apiClient<CustomerAddressCreateResponse>({
    url: customerAccountEndpoint,
    body: generateQuery({ address, defaultAddress }),
    headers,
    method: 'POST',
  });

  if (response?.errors && response.errors.length > 0) {
    throw new Error(response.errors.map((error) => error.message).join('; '));
  }

  if (
    response?.data?.customerAddressCreate?.userErrors &&
    response.data.customerAddressCreate.userErrors.length > 0
  ) {
    throw new Error(
      response.data.customerAddressCreate.userErrors.map((error) => error.message).join('; '),
    );
  }

  return response;
};

export default customerAddressCreate;

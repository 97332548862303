import { AuthTokens } from '../useAuth.types';
import { getExpireTimeInUtc } from '../useAuth.util';
import { RenewTokenResponse } from './renewToken.types';

const mapToAuthTokens = (renewedTokens: RenewTokenResponse, oldAuthTokens: AuthTokens) => {
  const expireTime = getExpireTimeInUtc(renewedTokens.expires_in);

  const renewedAuthTokens: AuthTokens = {
    ...oldAuthTokens,
    accessToken: renewedTokens.access_token,
    refreshToken: renewedTokens.refresh_token,
    expiresTimeInUtc: expireTime,
  };

  return renewedAuthTokens;
};

export default mapToAuthTokens;

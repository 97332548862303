import generateLogoutUrl from './generateLogoutUrl';

export interface LogoutProps {
  logoutEndpoint: string;
  idToken: string;
  redirectUri: string;
}

const logout = async ({ logoutEndpoint, idToken, redirectUri }: LogoutProps) => {
  const logoutUrl = await generateLogoutUrl({
    logoutEndpoint,
    idToken,
    redirectUri,
  });

  window.location.href = logoutUrl;
};

export default logout;

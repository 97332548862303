// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cachePromise: Promise<any> | null = null;

export const clearCachePromise = () => {
  cachePromise = null;
};

const createCachePromise = <T>(asyncFunction: () => Promise<T>): Promise<T> => {
  if (cachePromise) return cachePromise;

  cachePromise = (async () => {
    const result = await asyncFunction();
    cachePromise = null;

    return result;
  })();

  return cachePromise;
};

export default createCachePromise;

import apiClient from '~/api/apiClient';

export interface GetCustomerScopedAccessTokenProps {
  clientId: string;
  tokenExchangeAudience: string;
  accessToken: string;
  accessTokenEndpoint: string;
}

export interface CustomerScopedAccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  issued_token_type: string;
}

const generateRequestBody = ({
  clientId,
  tokenExchangeAudience,
  accessToken,
}: Omit<GetCustomerScopedAccessTokenProps, 'accessTokenEndpoint'>) => {
  const body = new URLSearchParams();
  body.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
  body.append('client_id', clientId);
  body.append('audience', tokenExchangeAudience);
  body.append('subject_token', accessToken);
  body.append('subject_token_type', 'urn:ietf:params:oauth:token-type:access_token');
  body.append('scopes', 'https://api.customers.com/auth/customer.graphql');

  return body;
};

const verifyResponse = (response: CustomerScopedAccessTokenResponse | undefined) => {
  if (!response) {
    throw new Error('Failed to get customer scoped token.');
  }

  return response;
};

const getCustomerScopedAccessToken = async ({
  clientId,
  tokenExchangeAudience,
  accessToken,
  accessTokenEndpoint,
}: GetCustomerScopedAccessTokenProps): Promise<CustomerScopedAccessTokenResponse> => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const body = generateRequestBody({ clientId, accessToken, tokenExchangeAudience });

  const response = await apiClient<CustomerScopedAccessTokenResponse>({
    url: accessTokenEndpoint,
    body: body.toString(),
    headers,
    method: 'POST',
  });

  return verifyResponse(response);
};

export default getCustomerScopedAccessToken;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-form"},[_c('BaseHeading',{staticClass:"heading",attrs:{"level":1,"title":"Sign up"}}),_vm._v(" "),_c('div',{staticClass:"form"},[(!_vm.isSignUpSuccessModal)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleRegister)}}},[_c('div',{staticClass:"signup-form__inputs"},[_c('p',[_vm._v("First Name*")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"signup-form__input",attrs:{"valid":!errors[0],"error-message":errors[0],"placeholder":"e.g. John","required":""},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_vm._v(" "),_c('p',[_vm._v("Last Name*")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"signup-form__input",attrs:{"valid":!errors[0],"error-message":errors[0],"placeholder":"e.g. Doe","required":""},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_vm._v(" "),_c('p',[_vm._v("Email*")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"signup-form__input",attrs:{"valid":!errors[0],"error-message":errors[0],"type":"email","placeholder":"e.g. johndoe@email.com","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('p',[_vm._v("Password*")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|passwordmin:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{staticClass:"signup-form__input",attrs:{"valid":!errors[0],"error-message":errors[0],"type":"password","placeholder":"Enter your password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_vm._v(" "),(_vm.error.register)?_c('div',{staticClass:"signup-form__error"},[_vm._v("\n          "+_vm._s(_vm.error.register)+"\n        ")]):_vm._e(),_vm._v(" "),_c('BaseButton',{staticClass:"sf-button--full-width signup-form__sign-up-btn",attrs:{"type":"submit","disabled":_vm.loading}},[_c('BaseLoader',{staticClass:"signup-form__loader",attrs:{"loading":_vm.loading}},[_vm._v(" Sign Up ")])],1)],1)]}}],null,false,3049599903)}):_c('div',{staticClass:"signup-notification"},[_c('h2',{staticClass:"signup-notification-title"},[_vm._v("\n        "+_vm._s(_vm.notification.title)+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"signup-notification-message"},[_vm._v("\n        "+_vm._s(_vm.notification.message)+"\n      ")]),_vm._v(" "),(_vm.notification.loginError)?_c('p',{staticClass:"signup-notification-message signup-form__error"},[_vm._v("\n        "+_vm._s(_vm.notification.loginError)+"\n      ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { AuthTokens } from '../useAuth.types';
import { getExpireTimeInUtc } from '../useAuth.util';
import { AccessTokenResponse } from './authenticate.types';

const mapToAuthTokens = (accessTokenResponse: AccessTokenResponse): AuthTokens => {
  const expireTime = getExpireTimeInUtc(accessTokenResponse.expires_in);

  return {
    accessToken: accessTokenResponse.access_token,
    idToken: accessTokenResponse.id_token,
    refreshToken: accessTokenResponse.refresh_token,
    expiresTimeInUtc: expireTime,
  };
};

export default mapToAuthTokens;

<template v-if="itemsCount">
  <div class="carousel-section">
    <div class="header-row">
      <div class="header-text">
        <BaseHeading :title="title" class="title" />
        <p v-if="description" class="description">{{ description }}</p>
      </div>
      <div class="actions">
        <BaseLink v-if="hasLink" :link="link.cached_url" class="link"
          >{{ linkText }} <FontAwesomeIcon class="link-icon" icon="fa-chevron-right"
        /></BaseLink>
        <BaseButton
          class="arrow-button sf-button--pure"
          :disabled="!shouldSlideLeft"
          @click="() => shouldSlideLeft && go('prev')"
        >
          <FontAwesomeIcon icon="fa-chevron-left" />
        </BaseButton>
        <BaseButton
          class="arrow-button sf-button--pure"
          :disabled="!shouldSlideRight"
          @click="() => shouldSlideRight && go('next')"
        >
          <FontAwesomeIcon icon="fa-chevron-right" />
        </BaseButton>
      </div>
    </div>
    <div class="carousel">
      <div ref="glide" class="carousel__track">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <slot />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import Glide from '@glidejs/glide';
import { BaseButton, BaseHeading, BaseLink } from '@unified-commerce/gpc-ui-library';

const GLIDEJS_XS_BREAKPOINT = 576;
const GLIDEJS_SM_BREAKPOINT = 768;
const GLIDEJS_MD_BREAKPOINT = 1200;
const GLIDEJS_PEEK_AMOUNT = 100;

export default {
  name: 'CarouselSection',
  components: {
    BaseButton,
    BaseHeading,
    BaseLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: [Object, String],
      default: () => ({}),
    },
    linkText: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    enableBullets: {
      type: Boolean,
      default: false,
    },
    activeBulletColor: {
      type: String,
      default: 'red',
    },
    inactiveBulletColor: {
      type: String,
      default: 'gray',
    },
    mobilePerView: {
      type: Number,
      default: 1,
    },
    tabletPerView: {
      type: Number,
      default: 3,
    },
    desktopPerView: {
      type: Number,
      default: 5,
    },
    enablePeeking: {
      type: Boolean,
      default: true,
    },
    fromWebsiteBanner: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    const remainingDots = props.tabletPerView - props.mobilePerView;
    const itemsCount = this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;

    return {
      glide: null,
      defaultSettings: {
        type: 'slider',
        rewind: false,
        perView: props.desktopPerView,
        slidePerPage: true,
        gap: 30,
        breakpoints: {
          [GLIDEJS_XS_BREAKPOINT]: {
            perView: Math.min(itemsCount, props.mobilePerView),
            peek: {
              before: 0,
              after: props.mobilePerView === 1 && !props.enableBullets ? GLIDEJS_PEEK_AMOUNT : 0,
            },
          },
          [GLIDEJS_SM_BREAKPOINT]: {
            perView: Math.min(
              itemsCount,
              remainingDots <= 1 ? 1 : Math.round((props.tabletPerView - props.mobilePerView) / 2),
            ),
            gap: 20,
            peek: {
              before: 0,
              after: 0,
            },
          },
          [GLIDEJS_MD_BREAKPOINT]: {
            perView: Math.min(itemsCount, props.tabletPerView),
            gap: 20,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      },
    };
  },
  computed: {
    hasLink() {
      return !!this.link.cached_url;
    },
    itemsCount() {
      return this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;
    },
    hasTrailingScene() {
      return this.itemsCount % this.perView !== 0;
    },
    perView(props) {
      if (this.glide) return this.glide.settings.perView;
      return props.desktopPerView;
    },
    numberOfScenes() {
      return Math.floor(this.itemsCount / this.perView) || 0;
    },
    currentIndex() {
      if (!this.glide) return;
      return this.glide.index;
    },
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      };
    },
    shouldSlideRight() {
      if (this.settings.type === 'carousel' && this.settings.rewind) {
        return true;
      }
      return this.currentIndex !== this.indexForTrailingScene();
    },
    shouldSlideLeft() {
      if (this.settings.type === 'carousel' && this.settings.rewind) {
        return true;
      }
      return !!this.currentIndex;
    },
    shouldPeekSlider() {
      if (!this.enablePeeking) {
        return 0;
      }
      return this.mobilePerView === 1 && !this.enableBullets ? GLIDEJS_PEEK_AMOUNT : 0;
    },
  },
  watch: {
    perView() {
      if (this.currentIndex > this.indexForTrailingScene()) {
        this.go(this.indexForTrailingScene());
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      const size = this.$slots?.default?.filter((slot) => slot.tag)?.length ?? 0;
      if (size <= glide.settings.perView) {
        glide.settings.perView = size;
        glide.settings.rewind = false;
      }
      glide.mount();
      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const { direction } = move;
        let page, newIndex;

        if (type === 'carousel' && rewind) {
          page = Math.ceil(glide.index / perView);
          newIndex = page * perView + (direction === '>' ? perView : -perView);
          if (newIndex >= size) {
            newIndex = 0;
          } else if (newIndex < 0 || newIndex + perView > size) {
            newIndex = size - perView;
          }
        } else {
          if (direction === '>') {
            newIndex = glide.index + perView;
            if (this.hasTrailingScene && newIndex > this.indexForTrailingScene()) {
              newIndex = this.indexForTrailingScene();
            }
            if (!this.hasTrailingScene && newIndex > this.indexForScene(this.numberOfScenes)) {
              newIndex = this.indexForScene(this.numberOfScenes);
            }
          } else if (direction === '<') {
            newIndex = glide.index - perView;
            if (this.hasTrailingScene && glide.index === this.indexForTrailingScene()) {
              newIndex = glide.index - (size % perView);
            }
            if (newIndex < 0) newIndex = 0;
          }
        }
        if (direction === '>' || direction === '<') {
          move.direction = '=';
          move.steps = newIndex;
        }

        const isGoingToLastSlide = newIndex === size - 1;

        if (isGoingToLastSlide) {
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.before = this.shouldPeekSlider;
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.after = 0;
        } else {
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.before = 0;
          glide.settings.breakpoints[GLIDEJS_XS_BREAKPOINT].peek.after = this.shouldPeekSlider;
        }
      });
      this.glide = glide;
    });
  },
  methods: {
    indexForScene(n) {
      return (n - 1) * this.perView;
    },
    indexForTrailingScene() {
      return this.itemsCount - this.perView;
    },
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
        default:
          this.glide.go(`=${direct}`);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.carousel-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--carousel-section-margin-bottom, --spacer-base);

  @include md-devices-min {
    margin-bottom: var(--carousel-section-margin-bottom, --spacer-lg);
  }
}

.header-text {
  flex: 1;
}

.title {
  --heading-text-align: left;
  --heading-title-color: var(--c-dark);
  --heading-title-font-family: var(--font-family--heading);
  --heading-title-font-size: 28px;
  --heading-title-font-weight: var(--font-weight--semibold);
  --heading-title-font-line-height: 36px;
  --heading-padding: 0;

  @include md-devices-min {
    --heading-title-font-size: 52px;
    --heading-title-font-line-height: 56px;
  }
}

.description {
  color: var(--c-neutral-600);
  display: none;

  @include md-devices-min {
    display: inline;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .link {
    color: var(--c-primary-600);
    font-weight: var(--font-weight--normal);
    font-size: 14px;
    margin: 0 16px;
    text-decoration: underline;

    @include md-devices-min {
      font-size: 18px;
    }
  }

  .link-icon {
    margin-left: 8px;
    width: 12px;
    height: 12px;

    @include md-devices-min {
      display: none;
    }
  }

  .arrow-button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: none;

    &[disabled='disabled'] {
      opacity: 0.2;
      cursor: default;
    }

    @include md-devices-min {
      display: block;
    }
  }
}

::v-deep {
  .sf-arrow:hover {
    --button-background: var(--c-primary-lighten);
  }

  .base-image {
    width: 100%;
  }

  .glide__slides {
    max-height: 500px;
    box-sizing: border-box;
    padding: 5px;

    @include sm-devices-min {
      max-height: unset;
    }
  }
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__track {
    width: 100%;
  }

  &__bullets {
    width: fit-content;
    display: flex;
    gap: var(--carousel-bullets-gap, 0px);
    margin: auto;
    margin-top: var(--carousel-bullets-margin-top, var(--spacer-3base));
    flex-wrap: wrap;
  }

  &__bullet-button {
    cursor: pointer;
    background: var(--carousel-bullet-button-background, none);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--carousel-bullet-button-height, 18px);
    width: var(--carousel-bullet-button-width, 18px);
    @media (hover: hover) {
      &:hover {
        transform: translate3d(0px, -2px, 0px);
      }
    }
    transition: transform 0.2s ease;
  }

  &__bullet {
    cursor: pointer;
    border: none;
    border-radius: 50%;
    min-width: 10px;
    height: 10px;
    background-color: var(--inactive-bullet-color);
    &--active {
      background-color: var(--active-bullet-color);
    }
  }
}

.glide__slides {
  min-width: 0;
}

::v-deep .is-disabled--button {
  background: white;
}
</style>
